import { lazy } from 'react';
// components
import Loadable from '../components/Loadable';
import AuthLayout from '../layouts/AuthLayout';
// routes
import routeNames from './routeNames';
// pages
const Login = Loadable(lazy(() => import('../pages/General/Login')));
const general = {
   path: '/',
   element: <AuthLayout />,
   children: [
      { path: routeNames.general.login, element: <Login /> }
   ]
};

export default general;