import './styles.scss';

const Loader = () => (
   <></>
   // <div className='loader'>
   //    <div className="progress">
   //       <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
   //    </div>
   // </div>
);

export default Loader;
