import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// components
import Loadable from '../components/Loadable';
import AdminLayout from '../layouts/AdminLayout';
import Page404 from '../pages/General/Page404';
// routes
import routeNames from './routeNames';
// pages
// Home
const Landing = Loadable(lazy(() => import('../pages/Admin/Landing')));
const ClientTestimonialsAdmin = Loadable(lazy(() => import('../pages/Admin/ClientTestimonialsAdmin')));
const GalleryAdmin = Loadable(lazy(() => import('../pages/Admin/GalleryAdmin')));
const SchoolTimingAdmin = Loadable(lazy(() => import('../pages/Admin/SchoolTimingAdmin')));
const FeeStructureAdmin = Loadable(lazy(() => import('../pages/Admin/FeeStructureAdmin')));
const NewsAndEventAdmin = Loadable(lazy(() => import('../pages/Admin/NewsAndEventAdmin')));
const ExamResultAdmin = Loadable(lazy(() => import('../pages/Admin/ExamResultAdmin')));
const ScienceAndTechnologyEventAdmin = Loadable(lazy(() => import('../pages/Admin/ScienceAndTechnologyEventAdmin')));
const NewsUpdateAdmin = Loadable(lazy(() => import('../pages/Admin/NewsUpdateAdmin')));
const GetInTouchAdmin = Loadable(lazy(() => import('../pages/Admin/GetInTouchAdmin')));
const JobApplicationAdmin = Loadable(lazy(() => import('../pages/Admin/JobApplicationAdmin')));
const AdmissionNoticeAdmin = Loadable(lazy(() => import('../pages/Admin/AdmissionNoticeAdmin')));
const AdmissionResultAdmin = Loadable(lazy(() => import('../pages/Admin/AdmissionResultAdmin')));
const JobAdmin = Loadable(lazy(() => import('../pages/Admin/JobAdmin')));

const admin = {
   path: '/',
   element: <AdminLayout />,
   children: [
      // Home
      { path: routeNames.admin.landing, element: <Landing /> },
      { path: routeNames.admin.clientTestimonial, element: <ClientTestimonialsAdmin /> },
      { path: routeNames.admin.gallery, element: <GalleryAdmin /> },
      { path: routeNames.admin.schoolTiming, element: <SchoolTimingAdmin /> },
      { path: routeNames.admin.feeStructure, element: <FeeStructureAdmin /> },
      { path: routeNames.admin.newsAndEvents, element: <NewsAndEventAdmin /> },
      { path: routeNames.admin.examResult, element: <ExamResultAdmin /> },
      { path: routeNames.admin.scienceAndTechnologyEvent, element: <ScienceAndTechnologyEventAdmin /> },
      { path: routeNames.admin.newsUpdate, element: <NewsUpdateAdmin /> },
      { path: routeNames.admin.getInTouch, element: <GetInTouchAdmin /> },
      { path: routeNames.admin.jobApplications, element: <JobApplicationAdmin /> },
      { path: routeNames.admin.admissionNotice, element: <AdmissionNoticeAdmin /> },
      { path: routeNames.admin.admissionResult, element: <AdmissionResultAdmin /> },
      { path: routeNames.admin.jobs, element: <JobAdmin /> },
      // 
      { path: routeNames.general.notFound, element: <Page404 /> },
      { path: '/', element: <Navigate to={routeNames.admin.landing} /> },
      { path: '*', element: <Navigate to={routeNames.admin.landing} /> },
   ]
};

export default admin;