import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { GoFile, GoHome } from 'react-icons/go';
import { GrGallery } from 'react-icons/gr';
import { BiTime, BiNews } from 'react-icons/bi';
import { MdOutlineEmojiEvents, MdOutlineTouchApp } from 'react-icons/md';
import { FaDollarSign } from 'react-icons/fa';
import { FcGraduationCap } from 'react-icons/fc';
import { BsFillCalendar2EventFill } from 'react-icons/bs';

import AdminHeader from './Header';
import routeNames from '../../routes/routeNames';

import './styles.scss';
import DeferredImage from '../../components/DeferredImage';

const AdminLayout = ({ t }) => {
   const [isExpanded, setIsExpended] = useState(true);
   const { pathname } = useLocation();
   const iconSize = isExpanded ? '20px' : "25px";

   return (
      <div className='sidebar'>
         <div className={`offcanvas offcanvas-start show ${isExpanded ? 'expanded' : ''}`} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
            <div className="offcanvas-header">
               <DeferredImage src='/logo-white.png' width='50px' height="45px" alt="logo" />
               <h3 className='heading text-white'>PISES</h3>
            </div>
            <div className="offcanvas-body">
               <ul className="nav-bar">
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.landing) ? 'active' : ''}`} href={routeNames.admin.landing}>
                        <GoHome size={iconSize} className='i' />
                        <span>{t("header.home")}</span>
                     </a>
                  </li>
                  {/* <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.clientTestimonial) ? 'active' : ''}`} href={routeNames.admin.clientTestimonial}>
                        <GoHome size={iconSize} className='i' />
                        <span>Quotes</span>
                     </a>
                  </li> */}
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.gallery) ? 'active' : ''}`} href={routeNames.admin.gallery}>
                        <GrGallery size={iconSize} className='i' />
                        <span>Gallery</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.schoolTiming) ? 'active' : ''}`} href={routeNames.admin.schoolTiming}>
                        <BiTime size={iconSize} className='i' />
                        <span>School Timing</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.feeStructure) ? 'active' : ''}`} href={routeNames.admin.feeStructure}>
                        <FaDollarSign size={iconSize} className='i' />
                        <span>Fee Structure</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.newsAndEvents) ? 'active' : ''}`} href={routeNames.admin.newsAndEvents}>
                        <BiNews size={iconSize} className='i' />
                        <span>News & Events</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.newsUpdate) ? 'active' : ''}`} href={routeNames.admin.newsUpdate}>
                        <BiNews size={iconSize} className='i' />
                        <span>News Update</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.examResult) ? 'active' : ''}`} href={routeNames.admin.examResult}>
                        <MdOutlineEmojiEvents size={iconSize} className='i' />
                        <span>Exam Result</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.scienceAndTechnologyEvent) ? 'active' : ''}`} href={routeNames.admin.scienceAndTechnologyEvent}>
                        <BsFillCalendar2EventFill size={iconSize} className='i' />
                        <span>Science & Technology Events</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.getInTouch) ? 'active' : ''}`} href={routeNames.admin.getInTouch}>
                        <MdOutlineTouchApp size={iconSize} className='i' />
                        <span>Get In Touch</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.admissionNotice) ? 'active' : ''}`} href={routeNames.admin.admissionNotice}>
                        <FcGraduationCap size={iconSize} className='i' />
                        <span>Admission Notice</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.admissionResult) ? 'active' : ''}`} href={routeNames.admin.admissionResult}>
                        <FcGraduationCap size={iconSize} className='i' />
                        <span>Admission Result</span>
                     </a>
                  </li>

                  <hr />
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.jobs) ? 'active' : ''}`} href={routeNames.admin.jobs}>
                        <GoFile size={iconSize} className='i' />
                        <span>Jobs</span>
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className={`${pathname?.includes(routeNames.admin.jobApplications) ? 'active' : ''}`} href={routeNames.admin.jobApplications}>
                        <GoFile size={iconSize} className='i' />
                        <span>Job Applications</span>
                     </a>
                  </li>

                  {/* About Us */}
                  {/* <li className="nav-item">
                     <a
                        className={`${(pathname?.includes(routeNames.admin.abc)) ? 'active' : ''}`}
                        href={routeNames.admin.abc}
                     >
                        <HiOutlineUsers size={iconSize} className='i' />
                        <span>{t("header.aboutUs")}</span>
                        <FiChevronDown className='arrow' />
                     </a>
                     <ul className="dropdown">
                        <li className="nav-item">
                           <a className={`${pathname?.includes(routeNames.admin.abc) ? 'active' : ''}`} href={routeNames.admin.abc}>
                              {t("header.missionAndVision")}
                           </a>
                        </li>
                        <li className="nav-item">
                           <a className={`${pathname?.includes(routeNames.admin.abc) ? 'active' : ''}`} href={routeNames.admin.abc}>
                              {t("header.learningGoals")}
                           </a>
                        </li>
                        <li className="nav-item">
                           <a className={`${pathname?.includes(routeNames.admin.abc) ? 'active' : ''}`} href={routeNames.admin.abc}>
                              {t("header.principalsMessage")}
                           </a>
                        </li>
                     </ul>
                  </li> */}
               </ul>
            </div>
         </div>
         <div className={`children ${isExpanded ? 'expanded' : ''}`}>
            <AdminHeader onClick={() => setIsExpended(!isExpanded)} />
            <div className='outlet'>
               <Outlet />
            </div>
         </div>
      </div>
   );
};

export default withTranslation()(AdminLayout);