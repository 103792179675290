import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../../../../components/Page';

import './styles.scss';

const AcademicsCalendarYear2022To2023 = ({ t }) => {
   return (
      <Page meta='Academics Calendar' title="Academics Calendar" className='academics-calendar' text="Academic Calendar Session 2024-2025">
         <div className='container'>
            <div className='card'>
               <img src="/assets/images/academics/calendar-33.jpg" width="100%" alt="calendar" />
               <img src="/assets/images/academics/calendar-4.jpg" width="100%" alt="calendar" />
            </div>
         </div>
      </Page>
   );
};

export default withTranslation()(AcademicsCalendarYear2022To2023);