import React from 'react';
import './styles.scss';

const Button = ({ title, className = '', secondary, cancel, ...rest }) => {
   return (
      <button className={`button-component btn ${className} ${secondary ? "secondary" : ''} ${cancel ? "cancel" : ''}`} {...rest}>
         <div>{title}</div>
      </button>
   )
}

export default Button