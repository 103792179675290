import { useState, useEffect } from 'react';

const useUser = () => {
   const token = localStorage.getItem('token');

   const getPayloadFromToken = (token) => {
      const encodedPayload = token.split('.')[1];
      let decodedToken = JSON.parse(atob(encodedPayload));
      if (decodedToken.role) return { ...decodedToken, roles: [decodedToken.role] };
      return decodedToken;
   };

   const [user, setUser] = useState(() => {
      if (!token) return null;
      return getPayloadFromToken(token);
   });

   useEffect(() => {
      if (!token) {
         setUser(null);
      } else {
         setUser(getPayloadFromToken(token));
      }
   }, [token]);

   return user;
};

export default useUser;
