import React from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { useUser } from '../../../hooks';
import routeNames from '../../../routes/routeNames';

import './styles.scss';

const AdminHeader = ({ onClick }) => {
   const user = useUser();
   const navigate = useNavigate();

   const handleLogout = () => {
      localStorage.removeItem('token');
      navigate({ to: routeNames.general.landing, replace: true });
   };

   return (
      <div className='admin-header'>
         <RxHamburgerMenu className='icon' size="30px" onClick={onClick}></RxHamburgerMenu>
         <div className='content'>
            <p className='desc'>
               <span className='bold'>{`${user?.name} `}</span>
               {`(${user.email})`}
            </p>
            {/* <p className='desc'>{user?.email}</p> */}
            <Button title="Logout" onClick={() => handleLogout()} />
         </div>
      </div>
   );
};

export default AdminHeader;