const routeNames = {
   general: {
      login: '/login',
      landing: '/home',
      virtualLearning: '/virtual-learning',
      cambridgeExamUpdates: '/cambridge-examUpdates',
      jobOpportunities: '/job-opportunities',
      newsEvents: {
         newsArchive: '/news-events/news-archive',
      },
      gallery: '/gallery',
      downloads: '/downloads',
      pisesianHangouts: 'pisesian-hangouts',
      pnsi: 'https://psni.weebly.com',
      portal: {
         staffPortal: "http://archivist5.manticapps.com/Security/SignIn",
         parentPortal: "http://parent.manticapps.com/pises",
         studentPortal: "https://outlook.office.com/mail/inbox",
         msTeams: "https://teams.microsoft.com/_",
      },
      aboutUs: {
         missionAndVision: '/about-us/mission-and-vision',
         learningGoals: '/about-us/learning-goals',
         principalsMessage: '/about-us/principal-message',
      },
      school: {
         lifeAtPises: '/school/life-at-pises',
         achievements: {
            examResults: '/school/achievements/exam-results',
            scienceAndTechnology: '/school/achievements/science-and-technology',
            otherAchievements: '/school/achievements/other',
         },
         pisesFacilities: '/school/pises-facilities',
         hygieneAndMedical: '/school/hygiene-and-medical',
         schoolTiming: '/school/timing',
         schoolUniform: '/school/uniform',
      },
      admissions: {
         admissionNotice: '/admissions/admission-notice',
         admissionGuidelines: '/admissions/admission-guidelines',
         admissionResult: '/admissions/admission-results',
         feeStructure: '/admissions/fee-structure',
         entryTestGuidelines: '/admissions/entry-test-guidelines',
         pisesBankDetails: '/admissions/pises-bank-details',
      },
      academics: {
         academicCalendar: '/academics/academic-calendar2024',
         academicCalendarTwentyfour: '/academics/academic-calendar2024',

         classesOffered: '/academics/classes-offered',
         preSchool: '/academics/pre-school',
         juniorSchool: '/academics/junior-school',
         // middleSchool: '/academics/middle-school',
         seniorSchool: '/academics/senior-school',
      },
      contactUs: '/contact-us',
      notFound: '/404'
   },
   admin: {
      landing: '/admin/home',
      clientTestimonial: '/admin/client-testimonial',
      gallery: '/admin/gallery',
      schoolTiming: '/admin/school-timing',
      feeStructure: '/admin/fee-structure',
      newsAndEvents: '/admin/news-and-events',
      examResult: '/admin/exam-result',
      scienceAndTechnologyEvent: '/admin/science-and-technology-event',
      newsUpdate: '/admin/news-update',
      getInTouch: '/admin/get-in-touch',
      jobs: '/admin/jobs',
      jobApplications: '/admin/job-applications',
      admissionNotice: '/admin/admission-notice',
      admissionResult: '/admin/admission-result',
   }
};

export default routeNames;