import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// components
import Loadable from '../components/Loadable';
import GeneralLayout from '../layouts/GeneralLayout';
// routes
import routeNames from './routeNames';
import AcademicsCalendarYear2023To2024 from '../pages/General/Academics/AcademicsCalendarYear2023To2024';
import AdmissionGuidelines from '../pages/General/Admissions/AdmissionGuidelines';
// pages
const Landing = Loadable(lazy(() => import('../pages/General/Landing')));
// About Us
const MissionAndVision = Loadable(lazy(() => import('../pages/General/AboutUs/MissionAndVision')));
const LearningGoals = Loadable(lazy(() => import('../pages/General/AboutUs/LearningGoals')));
const PrincipalsMessage = Loadable(lazy(() => import('../pages/General/AboutUs/PrincipalsMessage')));
// School
const LifeAtPises = Loadable(lazy(() => import('../pages/General/School/LifeAtPises')));
const ExamResults = Loadable(lazy(() => import('../pages/General/School/Achievements/ExamResults')));
const ScienceAndTechnology = Loadable(lazy(() => import('../pages/General/School/Achievements/ScienceAndTechnology')));
const OtherAchievements = Loadable(lazy(() => import('../pages/General/School/Achievements/OtherAchievements')));
const PisesFacilities = Loadable(lazy(() => import('../pages/General/School/PisesFacilities')));
const HygieneAndMedical = Loadable(lazy(() => import('../pages/General/School/HygieneAndMedical')));
const SchoolTiming = Loadable(lazy(() => import('../pages/General/School/SchoolTiming')));
const SchoolUniform = Loadable(lazy(() => import('../pages/General/School/SchoolUniform')));
// Admissions
const AdmissionNotice = Loadable(lazy(() => import('../pages/General/Admissions/AdmissionNotice')));
const AdmissionResultNew = Loadable(lazy(() => import('../pages/General/Admissions/AdmissionResultNew')));
const EntryTestGuidLines = Loadable(lazy(() => import('../pages/General/Admissions/EntryTestGuidLines')));
const FeeStructure = Loadable(lazy(() => import('../pages/General/Admissions/FeeStructure')));
const PisesBankDetails = Loadable(lazy(() => import('../pages/General/Admissions/PisesBankDetails')));
// Academics
// const AcademicsCalendarYear2022To2023 = Loadable(lazy(() => import('../pages/General/Academics/AcademicsCalendarYear2022To2023')));
const ClassesOffered = Loadable(lazy(() => import('../pages/General/Academics/ClassesOffered')));
const PreSchool = Loadable(lazy(() => import('../pages/General/Academics/PreSchool')));
const JuniorSchool = Loadable(lazy(() => import('../pages/General/Academics/JuniorSchool')));
const MiddleSchool = Loadable(lazy(() => import('../pages/General/Academics/MiddleSchool')));
const SeniorSchool = Loadable(lazy(() => import('../pages/General/Academics/SeniorSchool')));
// Contact Us
const ContactUs = Loadable(lazy(() => import('../pages/General/ContactUs')));
// 
const Page404 = Loadable(lazy(() => import('../pages/General/Page404')));
// 
const VirtualLearning = Loadable(lazy(() => import('../pages/General/VirtualLearning')));
const CambridgeExamUpdates = Loadable(lazy(() => import('../pages/General/CambridgeExamUpdate')));
const JobOpportunities = Loadable(lazy(() => import('../pages/General/JobOpportunities')));
const NewsArchive = Loadable(lazy(() => import('../pages/General/NewsAndEvents/NewsArchive')));
const Gallery = Loadable(lazy(() => import('../pages/General/Gallery')));
const Downloads = Loadable(lazy(() => import('../pages/General/Downloads')));

const general = {
   path: '/',
   element: <GeneralLayout />,
   children: [
      // Home
      { path: routeNames.general.landing, element: <Landing /> },
      // About Us
      { path: routeNames.general.aboutUs.missionAndVision, element: <MissionAndVision /> },
      { path: routeNames.general.aboutUs.learningGoals, element: <LearningGoals /> },
      { path: routeNames.general.aboutUs.principalsMessage, element: <PrincipalsMessage /> },
      // School
      { path: routeNames.general.school.lifeAtPises, element: <LifeAtPises /> },
      { path: routeNames.general.school.achievements.examResults, element: <ExamResults /> },
      { path: routeNames.general.school.achievements.scienceAndTechnology, element: <ScienceAndTechnology /> },
      { path: routeNames.general.school.achievements.otherAchievements, element: <OtherAchievements /> },
      { path: routeNames.general.school.pisesFacilities, element: <PisesFacilities /> },
      { path: routeNames.general.school.hygieneAndMedical, element: <HygieneAndMedical /> },
      { path: routeNames.general.school.schoolTiming, element: <SchoolTiming /> },
      { path: routeNames.general.school.schoolUniform, element: <SchoolUniform /> },
      // Admissions
      { path: routeNames.general.admissions.admissionNotice, element: <AdmissionNotice /> },
      { path: routeNames.general.admissions.admissionGuidelines, element: <AdmissionGuidelines /> },
      { path: routeNames.general.admissions.admissionResult, element: <AdmissionResultNew /> },
      { path: routeNames.general.admissions.entryTestGuidelines, element: <EntryTestGuidLines /> },
      { path: routeNames.general.admissions.feeStructure, element: <FeeStructure /> },
      { path: routeNames.general.admissions.pisesBankDetails, element: <PisesBankDetails /> },
      // Academics
      // { path: routeNames.general.academics.academicCalendar, element: <AcademicsCalendarYear2022To2023 /> },
      { path: routeNames.general.academics.academicCalendarTwentyfour, element: <AcademicsCalendarYear2023To2024 /> },
      { path: routeNames.general.academics.classesOffered, element: <ClassesOffered /> },
      { path: routeNames.general.academics.preSchool, element: <PreSchool /> },
      { path: routeNames.general.academics.juniorSchool, element: <JuniorSchool /> },
      { path: routeNames.general.academics.middleSchool, element: <MiddleSchool /> },
      { path: routeNames.general.academics.seniorSchool, element: <SeniorSchool /> },
      // Contact Us
      { path: routeNames.general.contactUs, element: <ContactUs /> },
      // 
      { path: routeNames.general.virtualLearning, element: <VirtualLearning /> },
      { path: routeNames.general.cambridgeExamUpdates, element: <CambridgeExamUpdates /> },
      { path: routeNames.general.jobOpportunities, element: <JobOpportunities /> },
      { path: routeNames.general.newsEvents.newsArchive, element: <NewsArchive /> },
      { path: routeNames.general.gallery, element: <Gallery /> },
      { path: routeNames.general.downloads, element: <Downloads /> },
      // 
      { path: routeNames.general.notFound, element: <Page404 /> },
      { path: '/', element: <Navigate to={routeNames.general.landing} /> },
      { path: '*', element: <Navigate to={routeNames.general.landing} /> },
   ]
};

export default general;