import React from 'react'
import { withTranslation } from 'react-i18next';
import routeNames from '../../routes/routeNames';
import { ArrowForwardFooter, FacebookSvg } from '../Svgs';

import './styles.scss';
import DeferredImage from '../DeferredImage';

const Footer = ({ t }) => {
   // return
   return (
      <div className='footer'>
         <div className='top-wrapper'>
            <div className='row gx-0 g-5'>
               <div className='col-12 col-lg-4 logo-div'>
                  <DeferredImage src="/assets/images/logo.png" width="161px" height="145.85px" alt="logo" />
                  <p className='desc'>{t("footer.desc")}</p>
               </div>
               <div className='col-12 col-lg-8 links-wrapper'>
                  <ul className="navbar-nav">
                     <li className="nav-item">
                        <h6 className="">{t("footer.navigate")}</h6>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.landing}>{t("footer.home")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.landing}>{t("footer.features")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.gallery}>{t("footer.gallery")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.aboutUs}>{t("footer.aboutUs")}</a>
                     </li>
                  </ul>
                  <ul className="navbar-nav">
                     <li className="nav-item">
                        <h6 className="">{t("footer.quickLinks")}</h6>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.school.pisesFacilities}>{t("footer.pisesFacilities")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.admissions}>{t("footer.admissions")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.aboutUs.learningGoals}>{t("footer.learningGoals")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.downloads}>{t("footer.downloads")}</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href={routeNames.general.contactUs}>{t("footer.contactUs")}</a>
                     </li>
                  </ul>
                  <div>
                     <input placeholder={t("footer.email")} className='footer-input' />
                     <div className='btn'>
                        <div>{t("footer.subscribe")}</div>
                        <ArrowForwardFooter />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='bottom-wrapper'>
            <p>
               {t("footer.copyRight1")}
               <span>{` ${t("footer.copyRight2")} `}</span>
               {t("footer.copyRight3")}
            </p>
            <div className='d-flex'>

               <FacebookSvg onClick={() => window.open('https://www.facebook.com/profile.php?id=100057376461651')} />
            </div>
         </div>
      </div>
   );
};

export default withTranslation()(Footer);