import React from 'react';
import { withTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

import './styles.scss';

const GeneralLayout = ({ t }) => {
   return (
      <>
         <Header />
         <div className='outlet'>
            <Outlet />
         </div>
         <Footer />
      </>
   );
};

export default withTranslation()(GeneralLayout);