import React from 'react';
import { withTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import './styles.scss';
import DeferredImage from '../../components/DeferredImage';

const AuthLayout = ({ t }) => {
   return (
      <>
         <div className='auth-layout'>
            <a className="navbar-brand" href="/">
               <DeferredImage src='/full-logo.jpeg' width='197px' height="60px" alt="logo" />
            </a>
         </div>
         <div className='outlet'>
            <Outlet />
         </div>
      </>
   );
};

export default withTranslation()(AuthLayout);