import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Banner from './Banner';
import './styles.scss';

const Page = React.forwardRef(({ children, title = '', text = '', meta, className = '', ...other }, ref) => (
   <>
      <Helmet>
         <title>{title || `Pakistan International School English Section (PISES), Riyadh.`}</title>
         {meta}
      </Helmet>

      {text ?
         <Banner text={text} />
         :
         null
      }

      <div className={`page ${className}`} ref={ref} {...other}>
         {children}
      </div>
   </>
));

export default Page;
