import React from 'react';
import Page from '../../../../components/Page';

import './styles.scss';

const AdmissionGuidelines = ({ t }) => {

   return (
      <Page meta='Admission Procedure' title="Admission Procedure" className='admission-guidelines' text="Admission Procedure">
         <div className='container'>
            <p className=''><strong>Ref</strong>: PISES/New-Adm/Doc/2024-25/001</p>
            <h2 className='center'>Pakistan International School</h2>
            <h3 className='center'>English Section, Riyadh, KSA</h3>
            <h5 className='center'>Guidelines for the Admission Process</h5>

            <h5>STEP 1 – Registration for the Admission Test (Nursery to Grade 10)</h5>

            <p className=''>All Pakistani nationals with valid legal status (Iqama Holders only) can apply for admission. A copy of the following documents must be submitted to the admission office along with the admission registration fee to complete the registration process.</p>
            <p className='li'><strong>a.</strong> candidate’s Iqama,</p>
            <p className='li'><strong>b.</strong> candidate’s passport,</p>
            <p className='li'><strong>c.</strong> Two recent photographs (passport size),</p>

            <p><strong>P.S.</strong> Registration will be done only for the classes where a seat is available. Admission registration timelines are posted on the school website. Usually, the process starts in the month of March.</p>

            <h5>STEP 2 – Entry Test</h5>
            <p>Registered candidates will be notified about the entry test day and time. The entry test schedule will not be changed. Actual admission will be subject to the performance in the entry test and the number of available seats.</p>


            <h5>STEP 3 – Entry Test Result Declaration</h5>
            <p>The list of the successful candidates will be posted on the school website and displayed in the school administration block on the dates announced. </p>


            <h5>STEP 4 – Documents and Fee Submission by the successful candidates.</h5>
            <p>The successful candidates need to submit the following documents: </p>
            <p>1-Admission Forms (duly filled)</p>
            <p>2-Copy of student’s Birth Certificate.</p>
            <p>3-Copy of the student’s valid Passport and Iqama</p>
            <p>4- Copy of valid Passport and Iqama of the father/guardian</p>
            <p>5-Copy of the student’s Vaccination/Immunization Certificate</p>
            <p>6-Passport-size photos of the student (two).</p>
            <p>7-Previous Report Cards photocopies. (From grade 1 to last grade)</p>
            <p>8- Financial Clearance from the previous school.</p>
            <p>9-If students are transferring from a school in Saudi Arabia, they must submit school report cards signed and stamped by the Ministry of Education, along with a printout of MOE’s “Noor” program showing the student ID number.</p>
            <p>10-If students are transferring from a school outside Saudi Arabia, their report cards must be attested by the Saudi Cultural Office/Council in the country where the student last attended the school.</p>
            <p>11-School leaving certificates from the previous school (stating the date of enrolment, the grade completed and the date of withdrawal).</p>
            <p>12-PISES reserves the right to deny admission/discontinue education if documentation for admission had been falsified or withheld.</p>
            <p>13-Incomplete admission forms will not be accepted.</p>

            <h5>Fee Submission</h5>
            <p>1-Please contact the admission office to pay the school fees. A student can join the class only after paying admission charges and tuition fees. Please see the fee structure and payment terms & conditions for further details.</p>
            <p>2- Parents failing to deposit the fee or submit the required documents according to the dates announced by the school will lose their seats, and admission will be offered to the next candidate on the waiting list.</p>
            <p>3-Depositing the fee amount partially will be considered as non-payment of the dues.</p>
            <p>4-Admission will be cancelled if parents fail to produce fee clearance documents from the previous school.</p>

            <h5>Admission to A-Levels</h5>
            <p>The admission process for A-Level candidates is held separately in the month of August after the declaration of IGCSE exam results. Interested candidates can contact the admission office after receiving their IGCSE results.</p>
         </div>
      </Page>
   );
};

export default AdmissionGuidelines;
